import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
// import { Provider } from 'react-redux'
// import thunk from 'redux-thunk'
// import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import './style/css/style.css'
// import authReducer from './store/reducers/auth'

import axios from 'axios'

// axios.defaults.baseURL = "http://localhost:8000/webresumeserver/"
axios.defaults.baseURL = "https://fullstackmangesh.com/webresumeserver/"
axios.defaults.headers.common['userID'] = 'd9023d7e209739120c39951fa818cb0803807043f8f95dc4f4cb92945d30b44ef6a1f744214be4f31793fd1c4f42d2659377f5b45b4bf6a49aba452c07301781'

axios.interceptors.request.use(request => {
    return request
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.reject(error)
})
/* const rootReducer = combineReducers({
    authReducer: authReducer
}) */

// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

/* const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
)) */
// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
/* const store = createStore(rootReducer, 
    applyMiddleware(thunk)
) */

ReactDOM.render(
    <React.StrictMode>
        {/* <Provider > */}
            <BrowserRouter>
                <App />
            </BrowserRouter>
        {/* </Provider> */}
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
