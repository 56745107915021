import React, { Component, Suspense, lazy } from "react";
import axios from 'axios'
import HomePage from "../../components/HomePage/HomePage"
/* import AboutMe from "../../components/Aboutme/Aboutme"
import Skills from "../../components/Skills/Skills"
import Experience from "../../components/Experience/Experience"
import Contact from "../../components/Contact/Contact" */
import Navbar from "../../components/Navigation/Navbar/Navbar"
// import LinkedInRecommendations from "../../components/LinkedInRecommendations/LinkedInRecommendations"
// import Footer from "../../components/Footer/Footer"
// import Modal from "../../components/ColorModal/ColorModal"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Snow from '../../components/Common/snow/snow'

const AboutMe = lazy(() => import('../../components/Aboutme/Aboutme'));
const Skills = lazy(() => import('../../components/Skills/Skills'));
const Experience = lazy(() => import('../../components/Experience/Experience'));
const Contact = lazy(() => import('../../components/Contact/Contact'));
const LinkedInRecommendations = lazy(() => import('../../components/LinkedInRecommendations/LinkedInRecommendations'));
const Footer = lazy(() => import('../../components/Footer/Footer'));
const Modal = lazy(() => import('../../components/ColorModal/ColorModal'));
// const AOS = lazy(() => import('aos'));

AOS.init({
    easing: 'ease-in-out',
    mirror: true
});

class Layout extends Component {

    state = {
        webresume: {},
        userID: "",
        isModalOpen: true,
        selectedTheme: 'base',
        themeClicked: false
    }

    componentDidMount() {
        axios.get('/getwebresume')
            .then(response => {
                if (response.data.code === 200) {
                    this.setState({
                        webresume: response.data.data.webResume,
                        userID: response.data.data.userID
                    })
                }
            })
    }

    modalClosedHandler = () => {
        this.setState({
            isModalOpen: false
        })
    }

    modalOpenHandler = () => {
        this.setState({
            isModalOpen: true,
            themeClicked: true
        })
    }

    selectedThemeHandler = (colorSuffix) => {
        this.setState({
            selectedTheme: colorSuffix,
            isModalOpen: false
        })
    }

    render() {
        let bgColor = 'bgcolor-secondary-' + this.state.selectedTheme;
        let textSelectionColor = 'text-selection-' + this.state.selectedTheme;
        let webresume = null;
        if (this.state.webresume.sections) {
            webresume = (<div className="layout">
                {this.state.selectedTheme === 'luxury' ? <Snow /> : null}
                {this.state.webresume.sections.navBar && <Navbar suffix={this.state.selectedTheme} modalOpen={this.modalOpenHandler} data={this.state.webresume.navBar} isModalOpen={this.state.isModalOpen}></Navbar>}
                <Suspense fallback={<div />}>
                    <Modal alreadyMounted={this.state.themeClicked} show={this.state.isModalOpen} suffix={this.state.selectedTheme} modalClosed={this.modalClosedHandler} selectedTheme={this.selectedThemeHandler} />
                </Suspense>
                <div className="container">
                    {this.state.webresume.sections.homePage && <HomePage showSVG={!this.state.isModalOpen} suffix={this.state.selectedTheme} data={this.state.webresume.homePage}></HomePage>}
                    <Suspense fallback={<div />}>
                        {this.state.webresume.sections.aboutMe &&
                            <AboutMe suffix={this.state.selectedTheme} data={this.state.webresume.aboutMe}></AboutMe>
                        }
                    </Suspense>
                    <Suspense fallback={<div />}>
                        {this.state.webresume.sections.skills &&
                            <Skills suffix={this.state.selectedTheme} data={this.state.webresume.skills}></Skills>
                        }
                    </Suspense>
                    <Suspense fallback={<div />}>
                        {this.state.webresume.sections.workExp &&
                            <Experience suffix={this.state.selectedTheme} data={this.state.webresume.workExp} ></Experience>
                        }
                    </Suspense>
                    <Suspense fallback={<div />}>
                        {this.state.webresume.sections.linkedIn &&
                            <LinkedInRecommendations suffix={this.state.selectedTheme} data={this.state.webresume.linkedIn}></LinkedInRecommendations>
                        }
                    </Suspense>
                    <Suspense fallback={<div />}>
                        {this.state.webresume.sections.contact &&
                            <Contact suffix={this.state.selectedTheme} data={this.state.webresume.contact}></Contact>
                        }
                    </Suspense>
                </div>
                <Suspense fallback={<div />}>
                    {this.state.webresume.sections.footer &&
                        <Footer suffix={this.state.selectedTheme} data={this.state.webresume.designedBy}></Footer>
                    }
                </Suspense>
            </div>)
        }
        return (
            <div className={bgColor + ' ' + textSelectionColor}>
                {webresume}
            </div>
        )
    }
}

export default Layout