import React, { Component } from 'react'
import { Link } from 'react-scroll'
import { scroller } from 'react-scroll'
import Logo from '../../Common/SVG/Logo/Logo'

class Navbar extends Component {
    state = {
        menuClicked: false,
        navData: this.props.data
    }

    menuChangedHandler = () => {
        if (!this.props.isModalOpen) {
            this.setState((prevState, prevProps) => {
                return {
                    ...prevState,
                    menuClicked: !prevState.menuClicked
                }
            })
        }
    }

    navigationSelected = () => {
        this.setState((prevState, prevProps) => {
            return {
                ...prevState,
                menuClicked: false
            }
        })
    }

    themeChangeHanlder = () => {
        this.navigationSelected()
        this.props.modalOpen()
        /* setTimeout(
            function() {
                this.props.modalOpen()
            }
            .bind(this),
            500
        ); */
    }

    handleSetActive = (to) => {
        let copiedData = [...this.state.navData];
        copiedData.forEach(element => {
            if (element.link === to) {
                element.active = true
            }
        });
        this.setState({
            navData: copiedData
        })
    }

    handleSetInActive = (to) => {
        let copiedData = [...this.state.navData];
        copiedData.forEach(element => {
            if (element.link === to) {
                element.active = false
            }
        });
        this.setState({
            navData: copiedData
        })
    }

    focusHomePageHandler = () => {
        scroller.scrollTo('home', {
            duration: 500,
            delay: 100,
            smooth: false,
            offset: -150,
        })
    }

    keyPressedEventHandler = (event, navItem) => {
        var code = event.keyCode || event.which;
        if (code === 13) {
            scroller.scrollTo(navItem, {
                duration: 500,
                delay: 100,
                smooth: false,
                offset: -150,
            })
        }
    }

    render() {
        let bgColor = 'bgcolor-secondary-' + this.props.suffix; //do not remove this. this will avoid transparent navbar issue
        let tertiaryColor = 'text-tertiary-' + this.props.suffix;
        let textPrimaryColor = 'text-primary-' + this.props.suffix;
        let hoverPrimaryColor = 'hovertext-primary-' + this.props.suffix;
        let bgColorPrimary = 'bgcolor-contrast-' + this.props.suffix;
        let focusPrimaryColor = 'focustext-primary-' + this.props.suffix;
        let menuBarClass = [];
        let animateClass = '';
        let linkVisibleClass = ''
        document.body.style.overflowY = 'auto';

        if (this.state.menuClicked) {
            linkVisibleClass = 'nav-bar__linkVisible';
            bgColor = 'bgcolor-secondary-single-' + this.props.suffix;
            menuBarClass = ['nav-bar__nav-active'];
            menuBarClass.push(bgColor)
            animateClass = 'nav-bar__nav-li-animate';
            bgColorPrimary = 'bgcolor-primary-' + this.props.suffix;
            document.body.style.overflowY = 'hidden';
        }
        let activeClasses = 'heading-small nav-bar__link ' + hoverPrimaryColor + ' ' + animateClass + ' ' + textPrimaryColor + ' ' + linkVisibleClass + ' ' + focusPrimaryColor;
        let inActiveClasses = 'heading-small nav-bar__link ' + hoverPrimaryColor + ' ' + animateClass + ' ' + tertiaryColor + ' ' + linkVisibleClass + ' ' + focusPrimaryColor;
        let navBarList = null;
        if (this.state.navData) {
            navBarList = (
                <div className="nav-bar__navigation">
                    <div className={'nav-bar__container ' + menuBarClass.join(' ')}>
                        {this.state.navData.map((navItem, index) => (
                            !navItem.hide && navItem.link !== "" ?
                                <Link className={navItem.active ? activeClasses : inActiveClasses} onSetActive={this.handleSetActive} onSetInactive={this.handleSetInActive} to={navItem.link} spy={true} smooth={false} offset={0} duration={500} key={index} onClick={this.navigationSelected}
                                    style={{
                                        opacity: 0,
                                        animation: `fadeIn 100ms ease-in ${(index + 2) * 50}ms`,
                                        animationFillMode: 'forwards'
                                    }}
                                    tabIndex="0"
                                    onKeyPress={(event) => { let focusedNavItem = navItem.link; this.keyPressedEventHandler(event, focusedNavItem) }}
                                >
                                    {navItem.navItemName}
                                </Link>
                                : !navItem.hide && navItem.navItemName === "Resume" ?
                                    <a href="./Mangesh-Resume.pdf" target="_blank" rel="noopener noreferrer" className={'heading-small nav-bar__link ' + hoverPrimaryColor + ' ' + tertiaryColor + ' ' + animateClass + ' ' + linkVisibleClass + ' ' + focusPrimaryColor} key={index} onClick={this.navigationSelected}
                                        style={{
                                            opacity: 0,
                                            animation: `fadeIn 100ms ease-in ${(index + 2) * 50}ms`,
                                            animationFillMode: 'forwards'
                                        }} >{navItem.navItemName}</a>
                                    : !navItem.hide ?
                                        <div role="button" onClick={this.themeChangeHanlder} className={'heading-small nav-bar__link ' + hoverPrimaryColor + ' ' + tertiaryColor + ' ' + animateClass + ' ' + linkVisibleClass + ' ' + focusPrimaryColor} key={index}
                                            style={{
                                                opacity: 0,
                                                animation: `fadeIn 100ms ease-in ${(index + 2) * 50}ms`,
                                                animationFillMode: 'forwards'
                                            }}
                                            tabIndex="0"
                                            onKeyPress={(event) => {
                                                var code = event.keyCode || event.which;
                                                if (code === 13) {
                                                    this.themeChangeHanlder()
                                                }
                                            }}
                                        >{navItem.navItemName}</div>
                                        : null
                        ))
                        }
                    </div>
                    <div className={'nav-bar__menu '} onClick={this.menuChangedHandler}>
                        <div className={this.state.menuClicked ? 'nav-bar__menu--1 ' + bgColorPrimary : bgColorPrimary}></div>
                        <div className={this.state.menuClicked ? 'nav-bar__menu--2 ' + bgColorPrimary : bgColorPrimary}></div>
                        <div className={this.state.menuClicked ? 'nav-bar__menu--3 ' + bgColorPrimary : bgColorPrimary}></div>
                    </div>
                </div>
            )
        }
        return (
            <nav className={'nav-bar ' + bgColor + ' ' + tertiaryColor}>
                <div className="nav-bar__logo" onClick={this.focusHomePageHandler}>
                    <Logo svgClass="nav-bar__logolink" suffix={this.props.suffix} />
                </div>
                {navBarList}
            </nav>
        )
    }
}


export default Navbar