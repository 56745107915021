import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import Layout from './containers/Layout/Layout'

class App extends Component {

    render() {
        return (
            <div>
                <Switch>
                    <Route path="/" exact component={Layout} />
                </Switch>
            </div>
        );
    }
}

export default App;
