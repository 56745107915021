import React from "react";

function Logo(props) {
  let primaryColor = 'logo-primary-' + props.suffix;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.svgClass}
      viewBox="0 0 85.68 85.68"
    >
      <path
        className={primaryColor}
        d="M42.84 0a42.84 42.84 0 1042.84 42.84A42.842 42.842 0 0042.84 0zm0 80.325A37.485 37.485 0 1180.325 42.84 37.485 37.485 0 0142.84 80.325z"
        transform="translate(-10 -10) translate(10 10)"
      ></path>
      <text
        className={primaryColor}
        fontFamily="OpenSans-Bold, Open Sans"
        fontSize="58"
        fontWeight="700"
        transform="translate(-10 -10) translate(52.02 59.9) translate(-26.368 13.111)"
      >
        <tspan x="0" y="0">
          M
        </tspan>
      </text>
    </svg>
  );
}

export default Logo;
