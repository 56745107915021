import React, { Component } from 'react'


class Snow extends Component {
    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
        this.documentHeight = document.body.scrollHeight;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.particlesOnScreen = 1000;
        if (this.width < 600) {
            this.particlesOnScreen = 500;
        }
        this.particlesArray = [];
    }

    componentDidMount() {
        this.canvas = this.canvasRef
        this.ctx = this.canvasRef.current.getContext('2d');
        this.createSnowFlakes();
        this.intervalId = setInterval(() => this.updateSnowFall(this.ctx), 50);
        window.addEventListener('resize', this.clientResize);
    }

    random(min, max) {
        return min + Math.random() * (max - min + 1);
    };

    clientResize(ev) {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.documentHeight = document.body.scrollHeight;
    };

    createSnowFlakes() {
        for (var i = 0; i < this.particlesOnScreen; i++) {
            this.particlesArray.push({
                x: Math.random() * this.width,
                y: this.random(-10,1) * this.height,
                opacity: Math.random(),
                speedY: this.random(1, 3.5),
                radius: this.width > 600 ? this.random(2, 3) : this.random(1, 2.5),
            })
        }
    };

    drawSnowFlakes() {
        for (var i = 0; i < this.particlesArray.length; i++) {
            var gradient = this.ctx.createRadialGradient(
                this.particlesArray[i].x,  // The x-axis coordinate of the start circle. 
                this.particlesArray[i].y,  // The y-axis coordinate of the start circle.
                0,                    // The radius of the start circle.
                this.particlesArray[i].x,  // The x-axis coordinate of the end circle.
                this.particlesArray[i].y,  // The y-axis coordinate of the end circle.
                this.particlesArray[i].radius // The radius of the end circle
            );


            gradient.addColorStop(0, "rgba(255, 255, 255," + this.particlesArray[i].opacity + ")");  // white
            gradient.addColorStop(.8, "rgba(210, 236, 242," + this.particlesArray[i].opacity + ")");  // bluish
            gradient.addColorStop(1, "rgba(237, 247, 249," + this.particlesArray[i].opacity + ")");   // lighter bluish

            this.ctx.beginPath();


            this.ctx.arc(
                this.particlesArray[i].x, // The x-axis (horizontal) coordinate of the arc's center.
                this.particlesArray[i].y, // The y-axis (vertical) coordinate of the arc's center.
                this.particlesArray[i].radius, // The radius. Must be non-negative.
                0,                        // The angle at which the arc starts,
                Math.PI * 2,                // The angle at which the arc ends
                false                     //  parametar whitch indicates wether the arc to be drawn counter-clockwise 
            );

            this.ctx.fillStyle = gradient;
            this.ctx.fill();
        }
    };

    moveSnowFlakes() {
        for (var i = 0; i < this.particlesArray.length; i++) {
            // this.particlesArray[i].x += this.particlesArray[i].speedX;
            this.particlesArray[i].y += this.particlesArray[i].speedY;

            if (this.particlesArray[i].y > this.documentHeight) {
                this.particlesArray[i].x = Math.random() * this.width * 1.5;
                this.particlesArray[i].y = -50;
            }
        }
    };

    updateSnowFall(ctx) {
        ctx.clearRect(0, 0, this.width, this.documentHeight)
        this.drawSnowFlakes()
        this.moveSnowFlakes()
    };

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    render() {
        return (
            <div className="wrapper" style={{"height" : `${this.documentHeight}px`}}>
                <canvas ref={this.canvasRef} height={this.documentHeight + 'px'} width={this.width + 'px'} />
            </div>
        )
    }
}

export default Snow;