import React, { Suspense, lazy } from 'react'
import { scroller } from 'react-scroll'
// import Pitching from '../Common/SVG/Undraw/Pitching';

const Pitching = lazy(() => import('../Common/SVG/Undraw/Pitching'));

const homePage = (props) => {
    const targetContactPageHandler = () => {
        scroller.scrollTo('contactPageLink', {
            duration: 500,
            delay: 100,
            smooth: false,
            offset: -150,
        })
    }
    let color = 'text-primary-' + props.suffix;
    let tertiaryColor = 'text-tertiary-' + props.suffix;
    let hoverPrimaryColor = 'hovertext-primary-' + props.suffix;
    let hoverBorderPrimaryColor = 'hoverborder-primary-' + props.suffix;
    let focusPrimaryColor = 'focustext-primary-' + props.suffix;

    return (

        <div className="home-page" id="home" name="home">
            <div className="home-page__content">
                <strong className={`body-regular home-page__greet ${tertiaryColor}`}>
                    Hi, my name is <br />
                </strong>
                <h1 className={'home-page-heading-bold home-page__name ' + color}>
                    {props.data.name}.
                </h1>
                <h2 className={'heading-medium home-page__work ' + tertiaryColor}>
                    {props.data.tagLine}
                </h2>
                <h3 className={'body-regular home-page__text ' + tertiaryColor}>{props.data.aboutme}</h3>
                <div className="home-page__cta">
                    <button onClick={targetContactPageHandler} disabled={!props.showSVG} className={'btn-primary ' + tertiaryColor + ' ' + hoverPrimaryColor + ' ' + hoverBorderPrimaryColor + ' ' + focusPrimaryColor}>Get In Touch</button>
                </div>
            </div>
            <div className="home-page__image">
                {props.showSVG ?
                    <Suspense fallback={<div />}>
                        <Pitching svgClass="home-page__pitching" skills={props.data.topSkills} suffix={props.suffix} />
                    </Suspense>
                    : null}
            </div>
        </div>
    )
}
export default homePage